import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const InstagramRedirect = () => {
  // Instagram profile URL
  const INSTAGRAM_URL = 'https://instagram.com/youthxconnect?utm_source=youthxconnect.com';

  // Handle redirect after animation
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = INSTAGRAM_URL;
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Redirecting to YouthXConnect Instagram</title>
        <meta name="robots" content="noindex, nofollow" />
        {/* Ensure proper mobile viewport scaling */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>

      {/* Mobile-optimized container with overflow prevention */}
      <div className="fixed inset-0 flex flex-col items-center justify-center bg-gradient-to-br from-purple-600 via-pink-600 to-orange-500 p-4 overflow-hidden">
        {/* Instagram Logo - Optimized for mobile-first */}
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="mb-6 w-16 h-16 md:w-24 md:h-24 flex items-center justify-center"
        >
          <svg 
            className="w-full h-full"
            viewBox="0 0 24 24"
            fill="#ffffff"
          >
            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
          </svg>
        </motion.div>

        {/* Mobile-optimized loading bar */}
        <motion.div
          initial={{ width: "0%" }}
          animate={{ width: "80%" }}
          transition={{ duration: 2, ease: "easeInOut" }}
          className="h-1 bg-white rounded-full mb-4 max-w-[250px]"
        />

        {/* Mobile-optimized text */}
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="text-lg md:text-xl font-bold text-center mb-2 text-white"
        >
          Redirecting to Instagram
        </motion.h1>
        
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
          className="text-sm md:text-base text-center text-white/80 px-4"
        >
          Taking you to YouthXConnect's Instagram profile
        </motion.p>
      </div>
    </>
  );
};

export default InstagramRedirect;
