// src/pages/JoinUs.js
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';
import ReCAPTCHA from 'react-google-recaptcha';

const JoinUs = () => {
  const formRef = useRef(null);
  const recaptchaRef = useRef(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [attachments, setAttachments] = useState([]);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length > 5) {
      alert('You can upload up to 5 files.');
      e.target.value = null;
      return;
    }

    for (let file of files) {
      if (file.size > 1 * 1024 * 1024) {
        alert('Each file must be less than 1MB.');
        e.target.value = null;
        return;
      }
    }

    setAttachments(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = formRef.current;
    const formData = new FormData(form);

    if (!captchaValue) {
      alert('Please verify that you are not a robot.');
      return;
    }

    const data = {
      content:
        '@everyone\n' +
        (attachments.length > 0
          ? attachments.map((file, index) => `Attached file: file${index}`).join('\n')
          : ''),
      embeds: [
        {
          title: 'New Join Request Submission',
          color: 0x0d6efd,
          fields: [
            {
              name: 'Full Name',
              value: formData.get('name') || 'N/A',
              inline: true,
            },
            {
              name: 'Email',
              value: formData.get('email') || 'N/A',
              inline: true,
            },
            {
              name: 'Why do you want to join?',
              value: formData.get('reason') || 'N/A',
            },
            {
              name: 'What skills do you bring?',
              value: formData.get('skills') || 'N/A',
            },
          ],
          timestamp: new Date().toISOString(),
          footer: {
            text: 'YouthXConnect Join Request',
          },
        },
      ],
    };

    const discordFormData = new FormData();
    discordFormData.append('payload_json', JSON.stringify(data));

    if (attachments.length > 0) {
      attachments.forEach((file, index) => {
        discordFormData.append(`file${index}`, file, file.name);
      });
    }

    try {
      await fetch('https://discord.com/api/webhooks/1301475124217643039/JvnBuuGTJg_-xkcas9grIl5BqqNKcX5OiqpoG84Dp-ot0TY4qWixAWZiH5hRUZLUPkad', {
        method: 'POST',
        body: discordFormData,
      });
      alert('Your application has been sent!');
      form.reset();
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setCaptchaValue(null);
      setAttachments([]);
    } catch (error) {
      console.error('Error sending application:', error);
      alert('There was an error submitting your application. Please try again later.');
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50 pt-16">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Join - YouthXConnect</title>
        <meta name="description" content="Join YouthXConnect and be a part of something bigger!" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Navbar />
      <motion.div
        className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-12 md:py-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1 className="text-4xl font-bold mb-12 text-center text-gray-900">Apply to work at YouthXConnect</h1>
        <div className="flex justify-center">
          <motion.div
            className="w-full max-w-2xl"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.7 }}
          >
            <form
              ref={formRef}
              onSubmit={handleSubmit}
              className="bg-white shadow-xl rounded-lg px-8 py-10 mb-4 space-y-6"
            >
              <div className="mb-6">
                <label className="block text-gray-800 text-sm font-semibold mb-2" htmlFor="name">
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  required
                  className="shadow-sm appearance-none border border-gray-300 rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Ramesh Sharma"
                />
              </div>

              <div className="mb-6">
                <label className="block text-gray-800 text-sm font-semibold mb-2" htmlFor="email">
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  required
                  className="shadow-sm appearance-none border border-gray-300 rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="you@example.com"
                />
              </div>

              <div className="mb-6">
                <label className="block text-gray-800 text-sm font-semibold mb-2" htmlFor="reason">
                  Why do you want to join?
                </label>
                <textarea
                  name="reason"
                  required
                  rows="4"
                  className="shadow-sm appearance-none border border-gray-300 rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                  placeholder="Tell us why you're interested in joining."
                ></textarea>
              </div>

              <div className="mb-6">
                <label className="block text-gray-800 text-sm font-semibold mb-2" htmlFor="skills">
                  What skills do you bring?
                </label>
                <textarea
                  name="skills"
                  required
                  rows="4"
                  className="shadow-sm appearance-none border border-gray-300 rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                  placeholder="Tell us about your skills and experience."
                ></textarea>
              </div>

              <div className="mb-6">
                <label className="block text-gray-800 text-sm font-semibold mb-2" htmlFor="attachments">
                  Attach Resume (Optional)
                </label>
                <input
                  type="file"
                  name="attachments"
                  accept="image/*,application/pdf"
                  onChange={handleFileChange}
                  className="shadow-sm appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <p className="text-gray-600 text-xs mt-1">
                  You can upload your resume or other documents (up to 5 files, Max 1MB each).
                </p>
              </div>

              <div className="mb-6 flex justify-center">
                <ReCAPTCHA
                  sitekey="6Leys3EqAAAAAEjkHcbcAjaoJ1Bf4o1xcG8W1Hv9"
                  onChange={handleCaptchaChange}
                  ref={recaptchaRef}
                />
              </div>

              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  disabled={!captchaValue}
                  className={`w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    !captchaValue ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  Submit Application
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default JoinUs;
