import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * TRNTBrochure Component
 * 
 * A mobile-first component that displays the TRNT brochure PDF with the following features:
 * - Responsive design optimized for mobile devices
 * - SEO-friendly meta tags
 * - Accessible PDF viewer
 * - Download functionality
 * - Proper spacing to avoid navbar overlap
 * 
 * @returns {JSX.Element} The rendered brochure page
 */
const TRNTBrochure = () => {
  // PDF source URL
  const PDF_URL = "https://drive.google.com/file/d/1gdkEfRF6E56IckS7mGbRUDBc2P87183z";
  
  return (
    <div className="min-h-screen bg-white">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>TRNT Brochure | The Road Not Taken</title>
        <meta 
          name="description" 
          content="Download the official TRNT brochure to learn more about The Rising New Tomorrow's initiatives and programs." 
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
      </Helmet>

      {/* Main Container - Mobile-first padding and spacing */}
      <div className="container mx-auto px-3 sm:px-4 pt-20 sm:pt-24 pb-6 sm:pb-8">
        <div className="max-w-3xl mx-auto"> {/* Reduced max-width for better mobile reading */}
          {/* Heading Section - Optimized for mobile */}
          <div className="text-center mb-4 sm:mb-8">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-800 mb-4">
              TRNT Brochure
            </h1>
          </div>

          {/* PDF Container with A4 aspect ratio and mobile-friendly shadow */}
          <div 
            className="relative w-full rounded-lg shadow-md sm:shadow-xl overflow-hidden bg-gray-50"
            style={{ paddingTop: '141.4%' }} // A4 aspect ratio (1:√2)
          >
            {/* PDF Viewer iframe - Positioned absolutely to maintain aspect ratio */}
            <iframe
              src={`${PDF_URL}/preview`}
              className="absolute top-0 left-0 w-full h-full border-0 rounded-lg"
              allow="autoplay"
              title="TRNT Brochure Viewer"
              loading="lazy" // Lazy load for better performance
            />
          </div>

          {/* Download Button Section - Mobile-optimized spacing and touch targets */}
          <div className="text-center mt-6 sm:mt-8">
            {/* Download Button with larger touch target for mobile */}
            <a
              href={`${PDF_URL}/view`}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center w-full sm:w-auto px-6 py-4 sm:py-3 
                       bg-blue-600 hover:bg-blue-700 active:bg-blue-800 
                       text-white font-semibold rounded-lg 
                       transition duration-300 ease-in-out transform hover:scale-105
                       focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              aria-label="Download TRNT Brochure"
            >
              {/* Download Icon */}
              <svg 
                className="w-5 h-5 mr-2" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" 
                />
              </svg>
              <span className="text-lg sm:text-base">Download Brochure</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TRNTBrochure;
