import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';

const PaymentPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Payment Policy - YouthXConnect</title>
        <meta
          name="description"
          content="Learn about YouthXConnect's Payment Policy, including accepted payment methods, billing procedures, and security measures."
        />
        <meta
          name="keywords"
          content="Payment Policy, YouthXConnect, Payment Methods, Billing, Transaction Security"
        />
        <meta property="og:title" content="Payment Policy - YouthXConnect" />
        <meta
          property="og:description"
          content="Learn about YouthXConnect's Payment Policy, including accepted payment methods, billing procedures, and security measures."
        />
        <meta property="og:image" content="%PUBLIC_URL%/payment-policy-og.png" />
        <meta property="og:url" content="https://www.youthxconnect.com/payment-policy" />
        <meta name="twitter:title" content="Payment Policy - YouthXConnect" />
        <meta
          name="twitter:description"
          content="Learn about YouthXConnect's Payment Policy, including accepted payment methods, billing procedures, and security measures."
        />
        <meta name="twitter:image" content="%PUBLIC_URL%/payment-policy-twitter.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Navbar />
      <motion.div
        className="container mx-auto px-4 sm:px-6 lg:px-8 py-12 pt-20 max-w-4xl"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className="text-3xl sm:text-4xl font-bold mb-6 text-center text-gray-900"
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          Payment Policy
        </motion.h1>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}>
          Last updated: October 31, 2024
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.4 }}
        >
          Accepted Payment Methods
        </motion.h2>
        <motion.div className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }}>
          <p>We accept the following payment methods:</p>
          <ul className="list-disc list-inside ml-4 mt-2">

            <li>Debit Cards (Visa, MasterCard, RuPay)</li>
            <li>UPI Payments (Google Pay, PhonePe, Paytm)</li>
            <li>Net Banking</li>
          </ul>
        </motion.div>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.6 }}
        >
          Transaction Security
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.7 }}>
          All payments are processed through secure payment gateways that comply with PCI DSS standards. Your financial information is encrypted and protected using industry-standard security protocols.
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.6 }}
        >
          Data Protection
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.7 }}>
          Your personal and payment information is handled with the utmost care and confidentiality. We do not share your data with third parties without your consent.
          <br/>
          <br/>
           Your data is stored in secure servers in India and is protected by industry-standard security measures. We take all necessary precautions to ensure the safety and integrity of your data.
          <br/>

        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.8 }}
        >
          Billing Process
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.9 }}>
          All amounts are in Indian Rupees (INR). You will receive a confirmation email with payment details after successful transaction completion. GST and other applicable taxes will be included in the final amount.
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.0 }}
        >
          Failed Transactions
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.1 }}>
          We do not take any responsibility for failed transactions due to technical issues, network problems, or any other reasons beyond our control. In case of a failed transaction, please contact your bank or payment gateway for assistance.
          <br />
          <br />
          If the payment amount is debited from your account, but our system does not show up the payment, In such cases we do not take any responsibility.
          <br />
          <br />
          In majoriy of the cases, the payment will be credited to your account within 7 business days. If the payment is not credited within 7 business days, please contact your bank or payment gateway for assistance.
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.2 }}
        >
          Contact Information
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.3 }}>
          <br />
          Email: <a href="mailto:connect@youthxconnect.com" 
          className="text-blue-500 underline">connect@YouthXConnect.com</a>
          <br />
          Phone: <a href="tel:+918851972135" className="text-blue-500 underline">+91 88519 72135</a>
          <br />
          (Monday to Friday, 9:00 AM to 5:00 PM IST)
          
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.4 }}
        >
          Policy Updates
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.5 }}>
          We reserve the right to modify this Payment Policy at any time. Changes will be effective immediately upon posting on our website.
        </motion.p>
      </motion.div>
    </div>
  );
};

export default PaymentPolicy;
