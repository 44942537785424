// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaYoutube, FaDiscord, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import logo from '../assets/yxc-banner-logo.png'; // Update with your logo path

const Footer = () => {
  return (
    <motion.footer
      className="bg-gradient-to-br from-blue-50/90 via-blue-100/80 to-blue-50/90 text-gray-700 py-12 border-t border-blue-100"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-6 lg:px-8">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-6">
            <img src={logo} alt="YouthXConnect Logo" className="h-10 w-auto mb-4" />
            <p className="text-gray-600 font-medium">Empowering Youth <br></br>for a Better Tomorrow</p>
          </div>
          <div className="w-full md:w-1/4 mb-6">
            <h3 className="text-lg font-semibold mb-4 text-blue-900">Quick Links</h3>
            <ul>
              <li className="mb-2">
                <Link to="/" className="hover:text-gray-900 transition">
                  Home
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/about" className="hover:text-gray-900 transition">
                  About Us
                </Link>
              </li>
              <li className="mb-2">
                <Link to="https://events.youthxconnect.com" className="hover:text-gray-900 transition">
                  Events at YxC
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-6">
            <h3 className="text-lg font-semibold mb-4 text-blue-900">Policies</h3>
            <ul>
            <li className="mb-2">
                <Link to="/refund-policy" className="hover:text-gray-900 transition">
                  Refund Policy
                </Link>
              </li>
             
              <li className="mb-2">
                <Link to="/privacy-policy" className="hover:text-gray-900 transition">
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/payment-policy" className="hover:text-gray-900 transition">
                  Payment Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/terms-of-service" className="hover:text-gray-900 transition">
                  Terms of Service
                </Link>
              </li>
             
             
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-6">
            <h3 className="text-lg font-semibold mb-4 text-blue-900">Connect With Us</h3>
            <div className="flex space-x-4 text-xl">
              <a 
                href="/youtube" 
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-red-600 transition-colors duration-300"
                aria-label="YouTube"
              >
                <FaYoutube />
              </a>
              <a 
                href="/x" 
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-black transition-colors duration-300"
                aria-label="X (Twitter)"
              >
                <FaXTwitter />
              </a>
              <a 
                href="/instagram" 
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-pink-600 transition-colors duration-300"
                aria-label="Instagram"
              >
                <FaInstagram />
              </a>
              <a 
                href="/discord" 
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-[#5865F2] transition-colors duration-300"
                aria-label="Discord"
              >
                <FaDiscord />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-600 font-medium">
          &copy; {new Date().getFullYear()} YouthXConnect. All rights reserved.
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;