import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const DiscordRedirect = () => {
  // Discord server invite URL
  const DISCORD_URL = 'https://discord.gg/sdrz73WRMs?utm_source=youthxconnect.com';

  // Handle redirect after animation
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = DISCORD_URL;
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Redirecting to YouthXConnect Discord</title>
        <meta name="robots" content="noindex, nofollow" />
        {/* Ensure proper mobile viewport scaling */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>

      {/* Mobile-optimized container with overflow prevention */}
      <div className="fixed inset-0 flex flex-col items-center justify-center bg-[#5865F2] p-4 overflow-hidden">
        {/* Discord Logo - Optimized for mobile-first */}
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="mb-6 w-16 h-16 md:w-24 md:h-24 flex items-center justify-center"
        >
          <svg 
            className="w-full h-full"
            viewBox="0 0 24 24"
            fill="#ffffff"
          >
            <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0 12.64 12.64 0 0 0-.617-1.25.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057 19.9 19.9 0 0 0 5.993 3.03.078.078 0 0 0 .084-.028c.462-.63.874-1.295 1.226-1.994.021-.041.001-.088-.041-.104a13.107 13.107 0 0 1-1.872-.892.077.077 0 0 1-.008-.128 10.2 10.2 0 0 0 .372-.292.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127 12.299 12.299 0 0 1-1.873.892.077.077 0 0 0-.041.105c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028 19.839 19.839 0 0 0 6.002-3.03.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z"/>
          </svg>
        </motion.div>

        {/* Mobile-optimized loading bar */}
        <motion.div
          initial={{ width: "0%" }}
          animate={{ width: "80%" }}
          transition={{ duration: 2, ease: "easeInOut" }}
          className="h-1 bg-white rounded-full mb-4 max-w-[250px]"
        />

        {/* Mobile-optimized text */}
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="text-lg md:text-xl font-bold text-center mb-2 text-white"
        >
          Redirecting to Discord
        </motion.h1>
        
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
          className="text-sm md:text-base text-center text-white/80 px-4"
        >
          Taking you to YouthXConnect's Discord server
        </motion.p>
      </div>
    </>
  );
};

export default DiscordRedirect;
