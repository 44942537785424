import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const XRedirect = () => {
  // X/Twitter profile URL
  const X_URL = 'https://x.com/youthxconnect?utm_source=youthxconnect.com';

  // Redirect after animation completes
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Prevent scrolling
    const timer = setTimeout(() => {
      window.location.href = X_URL;
    }, 2500);

    return () => {
      document.body.style.overflow = 'auto'; // Restore scrolling
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Redirecting to YouthXConnect X/Twitter</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>

      <div className="fixed inset-0 flex flex-col items-center justify-center bg-black p-4 overflow-hidden">
        {/* X Logo Animation */}
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="mb-6 w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24 flex items-center justify-center"
        >
          <svg 
            className="w-full h-full"
            viewBox="0 0 24 24"
            fill="#ffffff"
          >
            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
          </svg>
        </motion.div>

        {/* Loading Progress Bar */}
        <motion.div
          initial={{ width: "0%" }}
          animate={{ width: "80%" }}
          transition={{ duration: 2, ease: "easeInOut" }}
          className="h-1 bg-white rounded-full mb-4 max-w-[250px]"
        />

        {/* Text Content */}
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="text-white text-lg sm:text-xl md:text-2xl font-bold text-center mb-2"
        >
          Redirecting to X/Twitter
        </motion.h1>
        
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
          className="text-white/80 text-xs sm:text-sm md:text-base text-center px-4"
        >
          Taking you to YouthXConnect's X profile
        </motion.p>
      </div>
    </>
  );
};

export default XRedirect;
