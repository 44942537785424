// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Join from './pages/Join';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import RefundPolicy from './pages/RefundPolicy';
import PaymentPolicy from './pages/PaymentPolicy';
import Footer from './components/Footer';
import NotFound from './pages/NotFound';
import LoadingBar from 'react-top-loading-bar';
// ... social media redirect imports
import  YouTubeRedirect from './pages/smRedirects/yt';
import InstagramRedirect from './pages/smRedirects/ig';
import XRedirect from './pages/smRedirects/x';
import DiscordRedirect from './pages/smRedirects/dc';
import TRNTBrochure from './pages/embeds/trnt-brochure';


// A small component to control the loading bar on route change
function TopLoadingBar() {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const location = useLocation();

  useEffect(() => {
    setLoadingProgress(30);
    const loadingTimer = setTimeout(() => setLoadingProgress(100), 600);
    return () => clearTimeout(loadingTimer);
  }, [location]);

  return (
    <LoadingBar
      color="#00ffff"
      progress={loadingProgress}
      onLoaderFinished={() => setLoadingProgress(0)}
      height={8}
    />
  );
}

function App() {
  return (
    <Router>
      <TopLoadingBar />
      <ScrollToTop />
      <Navbar />
      <div className="min-h-screen">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/join" element={<Join />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/payment-policy" element={<PaymentPolicy />} />          

          <Route path="/youtube" element={<YouTubeRedirect />} />
          <Route path="/instagram" element={<InstagramRedirect />} />
          <Route path="/x" element={<XRedirect />} />
          <Route path="/discord" element={<DiscordRedirect />} />
          <Route path="/trnt-brochure" element={<TRNTBrochure />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />  
    </Router>
  );
}

export default App;