// src/pages/Home.js

import React from 'react';
import { Helmet } from 'react-helmet';
import AboutSection from '../components/AboutSection';
import Events from '../components/Events';
import MottoSection from '../components/MottoSection';
import { motion } from 'framer-motion';
import Banner from '../components/Banner'; // Import Banner
import { useState } from 'react';

const Home = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>YouthXConnect - Empowering Youth Through Leadership and Innovation</title>
        <meta name="description" content="Join YouthXConnect to develop leadership skills, drive innovation, and create positive social impact. Discover our programs, events, and community initiatives." />
        <meta name="keywords" content="youth leadership, innovation, community service, social impact, youth empowerment, leadership development, youth programs" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://youthxconnect.com/" />
        <meta property="og:title" content="YouthXConnect - Youth Leadership & Innovation" />
        <meta property="og:description" content="Empowering youth through leadership development, innovation programs, and community initiatives. Join our vibrant community today!" />
        <meta property="og:image" content="https://youthxconnect.com/yxc-banner-logo.png" />
        <meta property="og:site_name" content="YouthXConnect" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@YouthXConnect" />
        <meta name="twitter:creator" content="@YouthXConnect" />
        <meta name="twitter:title" content="YouthXConnect - Youth Leadership & Innovation" />
        <meta name="twitter:description" content="Empowering youth through leadership development, innovation programs, and community initiatives. Join our vibrant community today!" />
        <meta name="twitter:image" content="https://youthxconnect.com/yxc-banner-logo.png" />
        
        {/* WhatsApp */}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        
        {/* Additional SEO */}
        <meta name="author" content="YouthXConnect" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://youthxconnect.com/" />

        {/* Color Theme */}
        <meta name="theme-color" content="#1a56db" />
        <meta name="msapplication-TileColor" content="#1a56db" />
      </Helmet>
      {/* Removed Navbar and Banner to prevent duplication */}

      {/* Ensure that the main content has top padding to avoid overlap with fixed Navbar */}
      <div className="pt-2"> {/* Adjust 'pt-16' based on Navbar height */}
        
        <Banner /> {/* Add Banner here */}
        <motion.div
          className="container mx-auto px-4 sm:px-6 lg:px-8 py-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <AboutSection />
          <motion.div
            className="mt-8"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            <Events />
          </motion.div>
          
          <MottoSection />

          <div style={{ textAlign: 'center', marginTop: '20px' }}>

          </div>
          
          {/* Donation Button */}
          <DonationButton />

        </motion.div>
      </div>
      
    </div>
  );
};

const DonationButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDonationClick = async () => {
    setIsLoading(true);
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 800));
    window.location.href = 'https://pmny.in/DrG5vcvw8KZv'; // Replace with actual donation page URL
  };

  return (
    <motion.div
      className="flex justify-center mt-12 mb-16"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.5, duration: 0.6 }}
    >
      <motion.button
        className={`
          relative
          px-8 py-4
          text-lg font-bold
          text-white
          bg-gradient-to-r from-purple-600 to-pink-600
          rounded-full
          shadow-lg
          transform
          hover:scale-105
          hover:shadow-xl
          active:scale-95
          disabled:opacity-75
          disabled:cursor-not-allowed
          transition-all
          duration-200
          ease-in-out
          focus:outline-none
          focus:ring-4
          focus:ring-purple-500
          focus:ring-opacity-50
        `}
        whileHover={{ 
          boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
        }}
        whileTap={{ scale: 0.95 }}
        disabled={isLoading}
        onClick={handleDonationClick}
        aria-label="Make a donation"
      >
        <span className="flex items-center space-x-2">
          {isLoading ? (
            <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" />
            </svg>
          ) : (
            <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
            </svg>
          )}
          <span>{isLoading ? 'Processing...' : 'Donate'}</span>
        </span>
        <motion.div
          className="absolute -z-10 inset-0 rounded-full bg-gradient-to-r from-purple-600 to-pink-600 blur-lg opacity-50"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: 2
          }}
        />
      </motion.button>
    </motion.div>
  );
};

export default Home;