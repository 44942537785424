// src/pages/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const NotFound = () => {
  return (
    <motion.div 
      className="min-h-screen flex items-center justify-center bg-gray-100 px-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="text-center">
        <motion.h1 
          className="text-6xl md:text-8xl font-bold text-gray-800 mb-4"
          initial={{ y: -20 }}
          animate={{ y: 0 }}
        >
          404
        </motion.h1>
        <motion.p 
          className="text-xl md:text-2xl text-gray-600 mb-8"
          initial={{ y: 20 }}
          animate={{ y: 0 }}
        >
          Oops! Page not found
        </motion.p>
        <Link 
          to="/"
          className="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg
                     hover:bg-blue-700 transition-colors duration-300"
        >
          Return Home
        </Link>
      </div>
    </motion.div>
  );
};

export default NotFound;