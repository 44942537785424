// src/components/MobileMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const MobileMenu = ({ isOpen, toggle, navLinks }) => {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }} // Updated duration and easing
        onClick={toggle} // Close when clicking outside
      >
        <motion.div 
          className="fixed top-20 left-4 z-50 w-64 backdrop-blur-lg bg-white shadow-2xl border border-white/30 rounded-2xl overflow-hidden"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30, mass: 1 }} // Adjusted spring properties
          onClick={e => e.stopPropagation()} // Prevent closing when clicking menu
        >
          <nav className="py-2">
            {navLinks.map((link, index) => (
              <motion.div
                key={link.name}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.05 * index, duration: 0.3, ease: 'easeOut' }} // Added easing
              >
                <Link
                  to={link.path}
                  className="block px-4 py-3 text-base font-medium text-gray-900/70 hover:text-gray-900 hover:bg-white/30 transition-colors duration-200"
                  onClick={toggle}
                >
                  {link.name}
                </Link>
              </motion.div>
            ))}
          </nav>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default MobileMenu;