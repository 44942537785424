import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const YouTubeRedirect = () => {
  // YouTube channel URL
  const YOUTUBE_URL = 'https://youtube.com/@yxcindia/?utm_source=youthxconnect.com';

  // Redirect after animation completes
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = YOUTUBE_URL;
    }, 2500); // Redirect after 2.5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Redirecting to YouthXConnect YouTube</title>
        <meta name="robots" content="noindex, nofollow" />
        {/* Ensure proper mobile viewport scaling */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>

      {/* Mobile-optimized container with overflow prevention */}
      <div className="fixed inset-0 flex flex-col items-center justify-center bg-gradient-to-br from-red-600 to-red-800 p-4 overflow-hidden">
        {/* YouTube Logo - Optimized for mobile-first */}
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="mb-6 w-16 h-16 md:w-24 md:h-24 flex items-center justify-center" // Mobile-first sizing
        >
          <svg 
            className="w-full h-full"
            viewBox="0 0 24 24"
            fill="#ffffff"
          >
            <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/>
          </svg>
        </motion.div>

        {/* Mobile-optimized loading bar */}
        <motion.div
          initial={{ width: "0%" }}
          animate={{ width: "80%" }}
          transition={{ duration: 2, ease: "easeInOut" }}
          className="h-1 bg-white rounded-full mb-4 max-w-[250px]"
        />

        {/* Mobile-optimized text */}
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="text-lg md:text-xl font-bold text-center mb-2 text-white"
        >
          Redirecting to YouTube
        </motion.h1>
        
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
          className="text-sm md:text-base text-center text-white/80 px-4"
        >
          You'll be redirected to YouthXConnect's YouTube channel
        </motion.p>
      </div>
    </>
  );
};

export default YouTubeRedirect;
