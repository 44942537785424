// src/components/Navbar.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/yxc-banner-logo.png'; // Update with your logo path
import MobileMenu from './MobileMenu';
import { FiMenu } from 'react-icons/fi';

const Navbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const navLinks = [
    { name: 'Home', path: '/' },
    { name: 'About Us', path: '/about' },
    { name: 'Events', path: 'https://events.youthxconnect.com' },
    { name: 'Contact', path: '/contact' },
  ];

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-transparent backdrop-blur-md shadow-sm
                    transition-colors duration-300 hover:bg-white/10">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex items-center h-[72px] relative">
          {/* Mobile menu button */}
          <div className="md:hidden absolute left-0">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="p-2.5 text-gray-700 hover:bg-gray-100/80
                         rounded-lg transition-all duration-300 focus:outline-none 
                         focus:ring-2 focus:ring-[#FF9933]/30 active:scale-95"
              aria-expanded={isOpen}
            >
              <FiMenu className="h-5 w-5" />
            </button>
          </div>

          {/* Logo - Left aligned on desktop, centered on mobile */}
          <div className="flex-1 flex md:justify-start justify-center">
            <Link to="/" className="flex items-center">
              <img
                src={logo}
                alt="YouthXConnect Logo"
                className="h-12 w-auto transition-all duration-300 hover:scale-102 
                         filter drop-shadow-sm hover:drop-shadow-md"
              />
            </Link>
          </div>

          {/* Desktop navigation - Absolute positioned */}
          <div className="hidden md:block absolute right-0">
            <div className="flex items-center space-x-8">
              {navLinks.map((link) => (
                <Link
                  key={link.name}
                  to={link.path}
                  className={`${
                    location.pathname === link.path
                      ? 'text-[#FF9933] font-medium bg-gray-100/80'
                      : 'text-gray-800 hover:text-[#FF9933] hover:bg-gray-100/50'
                  } px-3 py-2.5 text-[15px] font-normal tracking-wide transition-all 
                    duration-300 relative group rounded-md`}
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="h-[4px] w-full bg-gradient-to-r from-[#FF9933] via-white to-[#138808] 
                      opacity-90 transition-opacity duration-300 hover:opacity-100" />
      <MobileMenu isOpen={isOpen} toggle={() => setIsOpen(false)} navLinks={navLinks} />
    </nav>
  );
};

export default Navbar;