import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const AboutSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = async () => {
    if (isMobile) {
      setIsLoading(true);
      await new Promise(resolve => setTimeout(resolve, 2000));
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 text-center">
      <p className="text-lg mb-4">
        YouthXConnect is an organization dedicated to empowering youth, fostering a culture of leadership, innovation, and service. With a vision to unleash the potential of every young individual, we provide diverse programs and initiatives that enable young leaders to connect, collaborate, and create positive change in their communities.
      </p>
      <Link to="/join">
        <button 
          onClick={handleClick}
          className="relative w-[150px] h-[50px] rounded-[10px] border border-[#03045e] overflow-hidden
          transition-all duration-500 ease-in z-[1] group"
        >
          <span className="relative z-[2] text-[#03045e] group-hover:text-[#e0aaff] 
          transition-colors duration-300 text-lg">
            {isLoading ? 'Loading...' : 'Join Us'}
          </span>
          <div className="absolute top-0 left-[-10px] w-0 h-full bg-[#240046] -skew-x-[15deg] 
          transition-all duration-500 group-hover:w-[58%] -z-[1]"></div>
          <div className="absolute top-0 right-[-10px] w-0 h-full bg-[#5a189a] -skew-x-[15deg] 
          transition-all duration-500 group-hover:w-[58%] -z-[1]"></div>
        </button>
      </Link>
    </div>
  );
};

export default AboutSection;
