// src/pages/AboutUs.js
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import FounderNote from '../components/FounderNote';

import 'swiper/css';
import 'swiper/css/pagination';
import { motion } from 'framer-motion';

// Import Pillar Project Images
import Pillar1 from '../assets/pillar-projects/1.png';
import Pillar2 from '../assets/pillar-projects/2.png';
import Pillar3 from '../assets/pillar-projects/3.png';
import Pillar4 from '../assets/pillar-projects/4.png';
import Pillar5 from '../assets/pillar-projects/5.png';
import Pillar6 from '../assets/pillar-projects/6.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

// Import all event images
const eventImages = Array.from({ length: 13 }, (_, i) => {
  return require(`../assets/oldEventPics/${i + 1}.png`);
});

// Convert mentorImages to an array for random selection

// Custom hook for image loading optimization
const useImageLoader = (src) => {
  const [isLoaded, setIsLoaded] = useState(false);
  
  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setIsLoaded(true);
  }, [src]);

  return isLoaded;
};

// Constants for card dimensions
const CARD_DIMENSIONS = {
  height: 'h-[420px]', // Fixed height for all cards
  width: 'w-full', // Responsive width
  imageHeight: 'h-48', // Fixed image height
};

const ImageGallery = () => {
  // Custom hook to track window width for responsive design
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calculate slides per view based on screen size
  const getSlidesPerView = () => {
    if (windowWidth < 640) return 1;
    if (windowWidth < 1024) return 2;
    return 3;
  };

  return (
    <section className="mt-16 mb-8">
      <h2 className="text-2xl sm:text-4xl font-bold text-gray-900 mb-8 text-center">
        Past Event Highlights
      </h2>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={getSlidesPerView()}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        className="w-full pb-12" // padding bottom for pagination dots
      >
        {eventImages.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="aspect-[4/5] relative rounded-lg overflow-hidden shadow-lg"> {/* Changed from aspect-square */}
              <img
                src={image}
                alt={`Event highlight ${index + 1}`}
                className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-105"
                loading="lazy"
              />
              {/* Optional overlay for hover effect */}
              <div className="absolute inset-0 bg-black bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity duration-300" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

const AboutUs = () => {

  const pillarProjects = [
    {
      id: '1',
      name: 'Youth Parliament',
      description:
        'Youth parliaments serve as dynamic platforms engaging young individuals in democratic processes. Providing a forum for discussions, solution proposals, and simulated parliamentary sessions, they empower youth, nurture leadership, and encourage civic participation. These initiatives aim to instill a sense of responsibility and active citizenship in the younger generation.',
      detailedDescription:
        'The Youth Parliament aims to offer a vibrant and inclusive platform for young individuals to actively engage in the democratic process, express their thoughts on social, political, and economic issues, and foster leadership, civic education, and advocacy. It empowers the next generation, equipping them with skills for positive societal contributions.',
      image: Pillar1, // Imported Image
      link: '#',
    },
    {
      id: '2',
      name: 'Youth Investment Forum',
      description:
        'Youth Investment Forums empower young individuals to explore diverse investment opportunities, fostering financial literacy, entrepreneurship, and strategic investment skills. With expert speakers, workshops, and networking, participants gain valuable insights for informed decision-making, contributing to their economic growth and development.',
      detailedDescription:
        'Youth Investment Forums aim to empower young individuals by imparting financial knowledge, skills, and resources, fostering confidence in investment decisions. Through education, networking, and exposure to opportunities, these forums inspire entrepreneurship and contribute to economic growth within communities.',
      image: Pillar2, // Imported Image
      link: '#',
    },
    {
      id: '3',
      name: 'Knowledge Tour',
      description:
        'Knowledge tours are curated educational experiences that enhance participants\' understanding of diverse subjects or locations. Led by experts, these journeys encompass visits to museums, historical sites, universities, and cultural landmarks. Through interactive learning and discussions, participants gain insights and expertise, fostering a profound appreciation for a range of topics and encouraging a commitment to lifelong learning.',
      detailedDescription:
        'A knowledge tour aims to enhance learning through hands-on experiences, guided tours, and expert discussions. Fostering curiosity and critical thinking, these tours encourage lifelong learning, personal growth, and a deeper appreciation for the world\'s diversity and complexity.',
      image: Pillar3, // Imported Image
      link: '#',
    },
    {
      id: '4',
      name: 'Ideathon',
      description:
        'An ideathon is a collective gathering promoting creativity and problem-solving. Participants collaborate to generate innovative ideas and solutions for specific challenges or opportunities. In a set time frame, they brainstorm, pitch, and develop ideas, vying for recognition and support. Ideathons also facilitate networking and the transformation of concepts into actionable projects or businesses.',
      detailedDescription:
        'Ideathon aims to unite diverse minds, promoting innovation to address challenges or uncover opportunities. Participants collaborate to generate and refine ideas, working towards actionable solutions. The goal is to identify innovative concepts with positive impacts in technology, business, and social or environmental realms.',
      image: Pillar4, // Imported Image
      link: '#',
    },
    {
      id: '5',
      name: 'Network and Knowledge Exchange Program',
      description:
        'A knowledge-sharing initiative promotes collaboration, innovation, and learning among participants from diverse backgrounds and organizations. Through workshops, conferences, and collaborative projects, it cultivates expertise exchange, fostering growth and development for individuals and communities involved.',
      detailedDescription:
        'The goal of a network and knowledge exchange initiative is to encourage collaboration, facilitate the exchange of expertise and ideas, promote innovation, and provide learning opportunities for participants from diverse backgrounds and organizations. Through workshops, conferences, and joint projects, the program aims to contribute to the growth and development of individuals and communities, fostering positive change and progress.',
      image: Pillar5, // Imported Image
      link: '#',
    },
    {
      id: '6',
      name: 'Activities on Discord',
      description:
        'A vibrant community platform where members engage in various interactive activities, fostering collaboration, networking, and continuous learning. Through channels dedicated to discussions, workshops, Q&A sessions, and social events, our Discord server serves as a hub for real-time communication and support among participants.',
      detailedDescription:
        'The Activities on Discord initiative aims to create an engaging and interactive online community where members can collaborate, network, and learn continuously. By hosting dedicated channels for discussions, workshops, Q&A sessions, and social events, the Discord server facilitates real-time communication, support, and knowledge sharing. This platform not only enhances participant experience but also fosters a sense of belonging and active involvement in our community.',
      image: Pillar6, // Imported Image
      link: '#',
    },
  ];

  // Function to get random image from mentorImagesArray

  // Assign random images to mentors if mentors from Firebase are not available

  // Enhanced ProjectCard component with consistent sizing
  const EnhancedProjectCard = ({ project }) => {
    const imageLoaded = useImageLoader(project.image);
    
    return (
      <div className={`${CARD_DIMENSIONS.width} ${CARD_DIMENSIONS.height} bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:shadow-lg`}>
        <div className={`${CARD_DIMENSIONS.imageHeight} relative overflow-hidden bg-gray-200`}>
          {!imageLoaded && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="animate-pulse w-full h-full bg-gray-300" />
            </div>
          )}
          <img
            src={project.image}
            alt={project.name}
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              imageLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            loading="lazy"
          />
        </div>
        <div className="p-4 flex flex-col justify-between h-[calc(100%-12rem)]">
          <div>
            <h3 className="text-xl font-semibold mb-2 line-clamp-2">{project.name}</h3>
            <p className="text-gray-600 text-sm line-clamp-4">{project.description}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About YouthXConnect - Our Mission, Vision & Programs</title>
        <meta name="description" content="Discover YouthXConnect's mission to empower youth through leadership programs, innovation initiatives, and community service. Learn about our impact and join us." />
        <meta name="keywords" content="about YouthXConnect, youth programs, leadership training, community initiatives, youth empowerment, social impact" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://youthxconnect.com/about" />
        <meta property="og:title" content="About YouthXConnect - Empowering Youth Leaders" />
        <meta property="og:description" content="Learn about YouthXConnect's mission to empower youth through leadership, innovation, and community service. Join our transformative programs!" />
          <meta property="og:image" content="https://youthxconnect.com/yxc-banner-logo.png" />
        <meta property="og:site_name" content="YouthXConnect" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@YouthXConnect" />
        <meta name="twitter:title" content="About YouthXConnect - Youth Empowerment Programs" />
        <meta name="twitter:description" content="Learn about YouthXConnect's mission to empower youth through leadership, innovation, and community service. Join our transformative programs!" />
        <meta name="twitter:image" content="https://youthxconnect.com/images/about-twitter.jpg" />
        
        {/* WhatsApp */}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        
        {/* Additional SEO */}
        <meta name="author" content="YouthXConnect" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://youthxconnect.com/about" />

        {/* Color Theme */}
        <meta name="theme-color" content="#1a56db" />
      </Helmet>
      <Navbar />
      {/* Adding padding to ensure content is not overlapped by the Navbar */}
      <main className="flex-grow pt-20 bg-gray-50"> {/* Reduced top padding for mobile */}
        <motion.div
          className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {/* About Us Header - Mobile optimized */}
          <section className="text-center mb-8 sm:mb-12">
            <motion.h1 className="text-3xl sm:text-5xl font-extrabold tracking-tight text-gray-900">
              About Us
            </motion.h1>
            <motion.p className="mt-4 sm:mt-6 text-base sm:text-lg text-gray-600 max-w-3xl mx-auto px-4">
            YouthXConnect is committed to fostering leadership, innovation, and service among young individuals. Our comprehensive programs and initiatives empower youth to become confident and visionary leaders who drive positive change.
            </motion.p>
          </section>

          {/* Pillar Projects Section - Mobile optimized grid */}
          <section className="mt-8 sm:mt-16">
            <motion.h2 className="text-2xl sm:text-4xl font-bold text-gray-900 mb-8 sm:mb-12 text-center">
              Pillar Projects
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
              {pillarProjects.map((project) => (
                <motion.div
                  key={project.id}
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.2 }}
                >
                  <EnhancedProjectCard project={project} />
                </motion.div>
              ))}
            </div>
          </section>

          {/* Founder Note Section */}
          <FounderNote />
          <ImageGallery />
          {/* Mentors Section - Optimized Swiper configuration */}
          
        </motion.div>
      </main>
      
    </div>
  );
};

export default AboutUs;